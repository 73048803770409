/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import _, {get,round} from 'lodash'
import {Link} from 'react-router-dom'
import {KTSVG} from '_metronic/helpers'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'
import {PROJECT_URL} from "Constants/routerConstants";
import moment from 'moment'

const MarketShare = ({className, chartHeight, chartColor, renderTooltip, ...props}) => {
  const chartRef = useRef(null);
  const projectId = get(props, 'projectId')

  const market_share_key = props.pages.market_share;
  const key_param = market_share_key > 0 ? `/${market_share_key}` : ''

  const rawMarketShareData = get(props, 'market_share_impressions', []);
  const market_share_impressions = Array.isArray(rawMarketShareData) 
    ? [...rawMarketShareData].sort((a, b) => new Date(a.datetime) - new Date(b.datetime))
    : [];
    
  const market_share = get(props, `market_share_impressions[${market_share_key}].results`, 0);
  const pre_market_share = get(props, `market_share_impressions[${market_share_key > 0 ? market_share_key - 1 : market_share_key }].results`, 0);
  const avg_market_share = round(( - pre_market_share + market_share) * 100, 4);

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor, market_share_impressions))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, market_share_impressions])

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title d-flex align-items-center'>
          <span className='card-label fw-bolder fs-3'>Market Share Impressions</span>
          {renderTooltip(
            "Market Share Impressions",
            "This is the percentage of search impressions your site captures across all targeted keywords. A higher market share means more visibility and greater opportunity to attract clicks and traffic."
          )}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex flex-column justify-content-end p-0'>
        <div>
          {/* begin::Stats */}
          <div className='card-p pt-5 bg-white flex-grow-1'>
            {/* begin::Row */}
            <div className='row g-0'>
              {/* begin::Col */}
              <div className='col mr-8'>
                {/* begin::Stat */}
                <div className='d-flex align-items-center'>
                  <div className="fw-bolder fs-1 text-primary">
                    {round(market_share*100, 4)}%
                    <span className={`fs-3 fw-bolder ps-5 ${avg_market_share >= 0 ? 'text-success' : 'text-danger'}`}>{avg_market_share >= 0 && '+'}{avg_market_share}%</span>
                  </div>
                </div>
                {/* end::Stat */}
              </div>
              {/* end::Col */}
            </div>
            {/* end::Row */}
          </div>
          {/* end::Stats */}

          {/* begin::Chart */}
          <div ref={chartRef} className='mixed-widget-3-chart card-rounded-bottom'></div>
          {/* end::Chart */}
        </div>

        <div className="card-p">
          <Link className={`btn btn-primary w-100 py-3 ${market_share_impressions.length === 0 ? 'pe-none' : ''}`} to={`${PROJECT_URL}/report/${projectId}/competition-map${key_param}`}>Learn more</Link>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartHeight, chartColor, market_share_impressions) => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  const market_share = market_share_impressions.map(v => v.results)
  const datetime = market_share_impressions.map(v => v.datetime)
  const market_share_data = market_share_impressions.length > 1 ? market_share : [...market_share, ...market_share]
  const datetime_data = market_share_impressions.length > 1 ? datetime : [...datetime, moment().format('YYYY-MM-DD')]
  const max_value = _.max(market_share_data) * 1.5;
  const min_value = _.min(market_share_data);

  return {
    series: [
      {
        name: 'Market Share',
        data: market_share_data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',//'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: datetime_data,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: min_value === 0 ? -1 : 0,
      max: max_value === 0 ? 1 : max_value,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      // y: {
      //   formatter: function (val) {
      //     return '$' + val + ' thousands'
      //   },
      // },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {MarketShare}
