/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'

const ExpectedImprovements = ({ className, domainchart, projectDetail, ranking_gain_data = [], ...props }) => {
    let optimisedData = [];
    let criticalData = [];
    let dotPlotData = [];

    const black_color = "#000";
    const orange_color = "#ffa500";

    ranking_gain_data.forEach(item => {
        if (item.sim !== "1") {
            return;
        }
        if (item.name === "Optimised+Critical Issues") {
            
        } else if (item.name === "Critical Issues") {
            criticalData.push({ x: item.rank, y: item.critical_line })
        }
        let color = item.name === "Optimised+Critical Issues" ? black_color : orange_color;
        dotPlotData.push({ x: item.rank, y: item.value, fillColor: color });
    });

    const series = [
        {
            name: 'Critical Issues',
            type: 'line',
            data: criticalData.splice(0, 30),
            color: orange_color
        },
    ];

    const options = {
        chart: {
            type: 'line'
        },
        series: series,
        xaxis: {
            type: 'numeric',
            title: {
                text: 'Number of Rankster Issues Addressed'
            }
        },
        yaxis: [
            {
                title: {
                    text: 'Search Positions Gained'
                },
                labels: {
                    formatter: function(value) {
                        return Math.round(value);
                    }
                }
            },
        ],
        markers: {
            size: 5
        },
        tooltip: {
            shared: false,
            intersect: true
        },
        legend: {
            position: 'top',
            formatter: function (seriesName, opts) {
                const hideInLegend = ['Value']
                if (hideInLegend.includes(seriesName)) {
                    return '';
                }
                return seriesName;
            }
        },
        title: {
            text: 'Rankster Rank Gain Improvements',
            align: 'center'
        },
        stroke: {
          curve: "smooth"
        }
    };
    
    const chartRef = useRef(null)

    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(
            chartRef.current,
            options
        )
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef])

    return <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
}

export { ExpectedImprovements }