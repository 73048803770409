import { fork } from "redux-saga/effects";
import helloSaga from "./helloSaga";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
import projectSaga from "./projectSaga";
import visualizationSaga from "./visualizationSaga";

export default function* rootSaga() {
    yield fork(userSaga); 
    yield fork(authSaga); 
    yield fork(helloSaga);    
    yield fork(projectSaga);    
    yield fork(visualizationSaga);    
}


