import React, { useEffect } from "react";
import { get } from "lodash";
import { Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { hasPermissionWithRouter } from "./../Utils/roleAndPermUtils";
import { trackPageView } from "../Utils/analyticsUtils";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AdminRoute = ({
  component: Component,
  layout: Layout,
  isAuthenticated,
  profile,
  visualization,
  ...rest
}) => {
  const location = useLocation();

  useEffect(() => {
    // Track page view when route changes
    if (profile) {
      // First try to get project ID from visualization data
      const pathParts = location.pathname.split('/');
      let projectId = null;

      // Check visualization data first
      if (visualization) {
        // Find the first project ID that has data in visualization
        const projectKeys = Object.keys(visualization);
        if (projectKeys.length > 0) {
          const activeProject = projectKeys.find(key => visualization[key]?.data);
          if (activeProject) {
            projectId = activeProject;
          }
        }
      }

      // If no project ID in visualization, try to get it from URL
      if (!projectId && pathParts[1] === 'projects' && !isNaN(pathParts[2])) {
        projectId = pathParts[2];
      }
      
      trackPageView(
        location.pathname,
        profile.email,
        projectId
      );
    }
  }, [location, profile, visualization]);

  const useStripe = get(rest, 'useStripe', false);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return hasPermissionWithRouter(rest.allows, profile) ? (
            <Layout
              routeName={rest.name}
              pageClass={rest.pageClass || ''}
              layoutConfig={rest.layoutConfig || {}}
            >
              {
                useStripe ?
                <Elements stripe={stripePromise}>
                  <Component {...props} />
                </Elements> :
                <Component {...props} />
              }
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: "/403",
                state: { referer: props.location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { referer: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  profile: state.user.profile,
  visualization: state.visualization
});

AdminRoute.prototype = {
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
  visualization: PropTypes.object
};

export default connect(mapStateToProps, null)(AdminRoute);
