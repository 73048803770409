import { combineReducers } from "redux";
import helloReducer from "./helloReducer";
import themeReducer from "./themeReducer";
import authentication from "./authReducer";
import userDashboardReducer from "./userDashboardReducer";
import user from "./userReducer";
import myProjectsReducer from "./myProjectsReducer";
import global from "./globalReducer";
import visualization from "../slices/visualizationSlice";

const rootReducer = combineReducers({
    global,
    myProjectsReducer,
    userDashboardReducer,
    themeReducer,
    helloReducer,
    authentication,
    user,
    visualization,
});

export default rootReducer;
