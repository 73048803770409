import { takeLatest, put, call } from 'redux-saga/effects';
import { getReportProjectApi } from 'Apis/projectApi';
import {
  fetchVisualizationStart,
  fetchVisualizationSuccess,
  fetchVisualizationError
} from '../slices/visualizationSlice';

function* fetchVisualizationData(action) {
  try {
    const projectId = action.payload;
    if (!projectId) {
      throw new Error('Project ID is required');
    }

    const response = yield call(getReportProjectApi, projectId);

    if (response?.status === 200) {
      const visualizationData = {
        ...response.data,
        visualisation_data: {
          ...(response.data?.visualisation_data || {}),
          market_share_impressions: Array.isArray(response.data?.visualisation_data?.market_share_impressions) 
            ? response.data.visualisation_data.market_share_impressions 
            : [],
          keyword_tracking: Array.isArray(response.data?.visualisation_data?.keyword_tracking)
            ? response.data.visualisation_data.keyword_tracking
            : [],
          rankster_scores: Array.isArray(response.data?.visualisation_data?.rankster_scores)
            ? response.data.visualisation_data.rankster_scores
            : [],
          top_ranking_keyword_stats: Array.isArray(response.data?.visualisation_data?.top_ranking_keyword_stats)
            ? response.data.visualisation_data.top_ranking_keyword_stats
            : [],
          content_gaps: Array.isArray(response.data?.visualisation_data?.content_gaps)
            ? response.data.visualisation_data.content_gaps
            : [],
          competition_maps: Array.isArray(response.data?.visualisation_data?.competition_maps)
            ? response.data.visualisation_data.competition_maps
            : [],
          recommendations: Array.isArray(response.data?.visualisation_data?.recommendations)
            ? response.data.visualisation_data.recommendations
            : [],
          ranking_gain_data: Array.isArray(response.data?.visualisation_data?.ranking_gain_data)
            ? response.data.visualisation_data.ranking_gain_data
            : []
        }
      };

      yield put(fetchVisualizationSuccess({
        data: visualizationData,
        projectId
      }));
    } else {
      throw new Error(response?.data?.message || 'Failed to fetch visualization data');
    }
  } catch (error) {
    console.error('Error fetching visualization data:', error);
    yield put(fetchVisualizationError({
      error: error?.message || 'An error occurred while fetching visualization data',
      projectId: action.payload
    }));
  }
}

export default function* visualizationSaga() {
  yield takeLatest(fetchVisualizationStart.type, fetchVisualizationData);
} 