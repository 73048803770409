import React, { useEffect } from "react";
import { Route } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { trackPageView } from "../Utils/analyticsUtils";

const FrontendRoute = ({ component: Component, layout: Layout, user, ...rest }) => {
    const location = useLocation();
    
    useEffect(() => {
        // Track page view when route changes
        if (user?.profile) {
            trackPageView(
                location.pathname,
                user.profile.email,
                null // No project ID for frontend routes
            );
        }
    }, [location, user]);

    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout routeName={rest.name}>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(FrontendRoute);
