/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import _, {get, round} from 'lodash'
import moment from 'moment'
import {KTSVG} from '_metronic/helpers'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'

const KeywordItem = ({className, chartColor, chartHeight, ...props}) => {
  const chartRef = useRef(null)
  const chartRef1 = useRef(null)

  const ranking_keywords = get(props, 'ranking_keywords', []).length;
  const lost = get(props, 'lost', []).length;
  const gained = get(props, 'gained', []).length;

  const pre_ranking_keywords = get(props, 'pre_ranking_keywords', []).length;
  const pre_lost = get(props, 'pre_lost', []).length;
  const pre_gained = get(props, 'pre_gained', []).length;

  const top_ranking_keyword_stats = get(props, 'top_ranking_keyword_stats', [])
  const keyword_key = get(props, 'keyword_key', 'top_3')

  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, ranking_keywords))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, ranking_keywords, lost, gained])

  useEffect(() => {
    if (!chartRef1.current) {
      return
    }

    const chart = new ApexCharts(chartRef1.current, chartOptions1(chartHeight, chartColor, top_ranking_keyword_stats, keyword_key))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef1, top_ranking_keyword_stats])


  const avg_lost = round(lost - pre_lost, 2);
  const avg_gained = round(gained - pre_gained, 2);

  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <div ref={chartRef} className='mixed-widget-4-chart'></div>
        {/* begin::Label */}
        <div className='d-flex align-items-center w-100'>
          <div className="w-100 text-center">
            <div className='text-muted fs-5 mb-2'>New</div>
            <div>
              {
                avg_gained >= 0 ?
                <KTSVG
                  path='/media/icons/duotune/arrows/arr081.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                /> :
                <KTSVG
                  path='/media/icons/duotune/arrows/arr082.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              }
              <span className={`fs-7 ${avg_gained >= 0 ? 'text-success' : 'text-danger'}`}>{avg_gained >= 0 && '+'}{gained}</span>
            </div>
          </div>
          <div className="w-100 text-center">
            <div className='text-muted fs-5 mb-2'>Lost</div>
            <div>
              {
                avg_lost > 0 ?
                <KTSVG
                  path='/media/icons/duotune/arrows/arr081.svg'
                  className='svg-icon-5 svg-icon-success ms-1'
                /> :
                <KTSVG
                  path='/media/icons/duotune/arrows/arr082.svg'
                  className='svg-icon-5 svg-icon-danger ms-1'
                />
              }
              <span className={`fs-7 ${avg_lost > 0 ? 'text-success' : 'text-danger'}`}>{avg_lost >= 0 && '+'}{lost}</span>
            </div>
          </div>
        </div>
        {/* end::Label */}
      </div>
      <div className='flex-grow-1'>
        <div ref={chartRef1} className='mixed-widget-4-chart'></div>
      </div>
    </>
  )
}

const chartOptions = (chartColor, chartHeight, current_rankster_score) => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  const labelColor = getCSSVariableValue('--bs-gray-700')

  return {
    series: [current_rankster_score],
    chart: {
      fontFamily: 'inherit',
      height: 100,
      width: 60,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '40%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '10px',
            fontWeight: '700',
            offsetY: 6,
            show: true,
            formatter: function (val) {
              return val// + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

const chartOptions1 = (chartHeight, chartColor, top_ranking_keyword_stats = [], keyword_key) => {
  const labelColor = getCSSVariableValue('--bs-gray-800')
  const strokeColor = getCSSVariableValue('--bs-gray-300')
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-light-' + chartColor)

  const top_ranking_keyword_stats_after_sort = [...top_ranking_keyword_stats].sort((a, b) => {
    return new Date(a.datetime) - new Date(b.datetime);
  });
  const ranking_keywords = top_ranking_keyword_stats_after_sort.map(v =>  get(v, `[${keyword_key}].ranking_keywords`, []).length)
  const datetime = top_ranking_keyword_stats_after_sort.map(v => v.datetime)
  const ranking_keywords_data = top_ranking_keyword_stats_after_sort.length > 1 ? ranking_keywords : [...ranking_keywords, ...ranking_keywords]
  const datetime_data = top_ranking_keyword_stats_after_sort.length > 1 ? datetime : [...datetime, moment().format('YYYY-MM-DD')]
  const max_value = _.max(ranking_keywords_data) * 1.5;
  const min_value = _.min(ranking_keywords_data);

  return {
    series: [
      {
        name: 'Ranking Keywords', // 'Net Profit',
        data: ranking_keywords_data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 50,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: datetime_data,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: min_value === 0 ? -1 : 0,
      max: max_value === 0 ? 1 : max_value,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  }
}

export {KeywordItem}
