import { Link } from "react-router-dom";

import * as actions from "../Constants/actionTypes";
import {
    setToken,
    setTokenRefresh,
    removeToken,
    removeStorage,
} from "Utils/localStorageUtils";
import { AlertError, AlertSuccess } from "Utils/alertUtils";
// import { LOGIN_URL } from "Constants/routerConstants";

const initState = {
    isAuthenticated: false,
    isLoading: false,
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        // reset password
        case actions.AT_AUTH_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_AUTH_RESET_PASSWORD_SUCCESS: {
            AlertSuccess(action.payload); 
            // setTimeout(function () {
            //     window.location.href = MAIN_PAGE_URL;
            // }, 3000);
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_AUTH_RESET_PASSWORD_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // active account
        case actions.AT_AUTH_ACTIVE_ACCOUNT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_AUTH_ACTIVE_ACCOUNT_SUCCESS: {
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_AUTH_ACTIVE_ACCOUNT_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // resend activation link
        case actions.AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS: {
            AlertSuccess(action.payload);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // forgot password
        case actions.AT_AUTH_FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_AUTH_FORGOT_PASSWORD_SUCCESS: {
            AlertSuccess(action.payload);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_AUTH_FORGOT_PASSWORD_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // signup
        case actions.AT_AUTH_SIGNUP_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_AUTH_SIGNUP_SUCCESS: {
            AlertSuccess(action.payload);
            // setTimeout(function () {
            //     window.location.href = MAIN_PAGE_URL;
            // }, 3000);

            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_AUTH_SIGNUP_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_AUTH_REDIRECT_TO_LOGIN_PAGE:
            return {
                ...state,
                isLoading: false,
            };
        // login
        case actions.AT_AUTH_LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                isLoading: true,
            };
        case actions.AT_AUTH_LOGIN_SUCCESS: {
            const { access, refresh } = action.payload;            
            setToken({ access });   
            setTokenRefresh({ refresh });           
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
            };
        }
        case actions.AT_AUTH_LOGIN_FAILURE:
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
            };
        case actions.AT_AUTH_LOGOUT:
            removeToken();
            removeStorage();
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default myReducer;
