import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    fetchVisualizationStart: (state, action) => {
      const projectId = action.payload;
      state[projectId] = {
        ...state[projectId],
        loading: true,
        error: null
      };
    },
    fetchVisualizationSuccess: (state, action) => {
      const { projectId, data } = action.payload;
      state[projectId] = {
        data,
        loading: false,
        error: null
      };
    },
    fetchVisualizationError: (state, action) => {
      const { projectId, error } = action.payload;
      state[projectId] = {
        ...state[projectId],
        loading: false,
        error
      };
    }
  }
});

export const {
  fetchVisualizationStart,
  fetchVisualizationSuccess,
  fetchVisualizationError
} = visualizationSlice.actions;

export default visualizationSlice.reducer; 