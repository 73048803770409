/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {forEach, get} from 'lodash'

import {getCSS, getCSSVariableValue} from '_metronic/assets/ts/_utils'

const DomainPerformanceChart = ({className, domainchart, projectDetail}) => {
  const chartRef = useRef(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(domainchart, projectDetail, height)
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
}

export {DomainPerformanceChart}

function getChartOptions(domainchart, projectDetail, height) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  // const borderColor = getCSSVariableValue('--bs-gray-200')
  // const baseColor = getCSSVariableValue('--bs-info')
  // const lightColor = getCSSVariableValue('--bs-gray-400')
  const primaryColor = getCSSVariableValue('--bs-primary')
  const successColor = getCSSVariableValue('--bs-success')

  let line_datas = []
  let all_other_datas = []
  let current_client_datas = []
  // const client_url = get(api_project, 'client_url', '');
  // const display_name = get(api_project, 'display_name', '');
  const client_url = get(projectDetail, 'website', '')
  const display_name = get(projectDetail, 'name', '')

  const get_size_variable = (value) => {
    if (value <= 5) {
      return 5
    }
    return value
  }

  forEach(domainchart, (v, k) => {
    if (v.domain === client_url) {
      current_client_datas = [
        ...current_client_datas, 
        {
          x: v.avg_rank, 
          y: v.domain_keyword_penetration, 
          z: get_size_variable(v.domain_search_volume), 
          domain: v.domain, 
          domain_search_volume: v.domain_search_volume,
          num_ranking_keywords: v.num_ranking_keywords,
        }
      ]
    } else {
      all_other_datas = [
        ...all_other_datas, 
        {
          x: v.avg_rank, 
          y: v.domain_keyword_penetration, 
          z: get_size_variable(v.domain_search_volume), 
          domain: v.domain, 
          domain_search_volume: v.domain_search_volume,
          num_ranking_keywords: v.num_ranking_keywords,
        }
      ]
    }
    const trendline = get(v, 'trendline')
    if (trendline) {
      line_datas = [...line_datas, [v.avg_rank, trendline.toFixed(1), v.domain]]
    }
  })

  let series = [
    {
      name: 'All Other',
      // type: 'scatter',
      type: 'bubble',
      data: all_other_datas,
    },
    {
      name: display_name,
      // type: 'scatter',
      type: 'bubble',
      data: current_client_datas,
    },
  ]

  // if (line_datas.length > 0) {
  //   series = [
  //     ...series,
  //     {
  //       name: 'Trendlines',
  //       type: 'line',
  //       data: line_datas,
  //     },
  //   ]
  // }

  return {
    series,
    chart: {
      height: 800,
      // type: 'scatter',
      // type: 'line',
      type: 'bubble',
      zoom: {
        enabled: true,
        type: 'xy',
      },
    },
    // fill: {
    //   type:'solid',
    // },
    xaxis: {
      tickAmount: 10,
      labels: {
        formatter: function (val) {
          return parseFloat(val).toFixed(1)
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      title: {
        text: 'Average Search Engine Position',
      },
    },
    yaxis: {
      tickAmount: 7,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: function (value) {
          // Format the Y-axis gridline label numbers without decimals
          return Math.floor(value); // This example rounds down to the nearest integer
        }
      },
      title: {
        text: 'Search Term Market Penetration',
      },
    },
    tooltip: {
      enabledOnSeries: [1, 1, 0], // hides Trendlines
      shared: false,
      style: {
        fontSize: '12px',
      },
      // x: {
      //   formatter: function (val) {
      //     return 'AVG Search: ' + val
      //   },
      // },
      // y: {
      //   title: {
      //     formatter: (seriesName) => 'Search Term Market Penetration: ',
      //   },
      // },
      // z: {
      //   title: 'Domain: ',
      //   formatter: undefined,
      // },
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return `
            <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
              AVG Search: ${parseFloat(data.x).toFixed(1)}
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">
              <span class="apexcharts-tooltip-marker" style="background-color: transparent" ></span>
              <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">
                <div class="apexcharts-tooltip-y-group">
                  <span class="apexcharts-tooltip-text-y-label">Search Term Market Penetration: </span>
                  <span class="apexcharts-tooltip-text-y-value">${Math.floor(data.y)}</span>
                </div>
                <div class="apexcharts-tooltip-z-group">
                  <span class="apexcharts-tooltip-text-z-label">Domain: </span>
                  <span class="apexcharts-tooltip-text-z-value">${data.domain}</span>
                </div>
                <div class="apexcharts-tooltip-y-group">
                  <span class="apexcharts-tooltip-text-z-label">Domain Search Volume: </span>
                  <span class="apexcharts-tooltip-text-z-value">${data.domain_search_volume}</span>
                </div>
                <div class="apexcharts-tooltip-z-group">
                  <span class="apexcharts-tooltip-text-z-label">Ranking Keywords: </span>
                  <span class="apexcharts-tooltip-text-z-value">${data.num_ranking_keywords}</span>
                </div>
              </div>
            </div>
        `;
      }
    },
    colors: ['transparent', primaryColor, successColor],
    legend: {
      position: 'right',
      formatter: (seriesName, opts) => {
        if (opts.seriesIndex === 2) return '' // hides Trendlines label
        return seriesName
      },
      markers: {
        width: [12, 12, 0], // hides Trendlines marker
      },
    },
    markers: {
      size: [5, 10],
      colors: ['transparent', primaryColor],
      strokeColor: ['#000', 'transparent'],
      strokeWidth: [1, 0],
    },
    dataLabels: {
      enabled: false,
    },
  }
}
