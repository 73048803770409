import ReactGA from 'react-ga4';

// Initialize GA4 with your measurement ID
export const initGA = () => {
  const isDevelopment = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  
  // Basic initialization with minimal config to ensure events are sent
  ReactGA.initialize('G-WRT6K0CQ9C', {
    gtagOptions: {
      debug_mode: isDevelopment,
      send_page_view: false
    }
  });

  // Send an initial event to verify tracking is working
  ReactGA.event({
    category: 'System',
    action: 'Initialize',
    label: window.location.hostname,
    value: Date.now()
  });
};

// Get the page type from the path
const getPageType = (path) => {
  const parts = path.split('/');
  return parts[parts.length - 1] || 'home';
};

// Track page views
export const trackPageView = (path, userEmail, projectId) => {
  const pageType = getPageType(path);
  
  // Send as a GA4 event since it provides better visibility
  const eventData = {
    category: 'Page View',
    action: pageType,
    label: path,
    value: Date.now(),
    user_id: userEmail, // GA4 specific user identifier
    user_properties: {
      user_email: userEmail,
      project_id: projectId
    },
    params: {
      page_type: pageType,
      page_path: path,
      page_title: document.title
    }
  };

  ReactGA.event(eventData);
};

// Track custom events
export const trackEvent = (category, action, label, userEmail, projectId) => {
  const eventData = {
    category,
    action,
    label,
    value: Date.now(),
    user_id: userEmail, // GA4 specific user identifier
    user_properties: {
      user_email: userEmail,
      project_id: projectId
    }
  };

  ReactGA.event(eventData);
};

// Predefined events
export const ANALYTICS_EVENTS = {
  PROJECT: {
    CREATE: 'create_project',
    UPDATE: 'update_project',
    DELETE: 'delete_project',
    VIEW: 'view_project'
  },
  USER: {
    SIGNUP: 'user_signup',
    LOGIN: 'user_login',
    LOGOUT: 'user_logout',
    UPDATE_PROFILE: 'update_profile'
  },
  RECOMMENDATIONS: {
    VIEW: 'view_recommendations',
    IMPLEMENT: 'implement_recommendation'
  }
}; 